* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
@font-face {
  font-family: KS-extrabold;
  src: url("../src/assets/fonts/Kumbh_Sans/KumbhSans-ExtraBold.ttf");
}
@font-face {
  font-family: KS-bold;
  src: url("../src/assets/fonts/Kumbh_Sans/KumbhSans-Bold.ttf");
}
@font-face {
  font-family: S-bold;
  src: url("../src/assets/fonts/Satoshi_Complete/Satoshi-Bold.otf");
}
@font-face {
  font-family: S-medium;
  src: url("../src/assets/fonts/Satoshi_Complete/Satoshi-Medium.otf");
}
@font-face {
  font-family: S-regular;
  src: url("../src/assets/fonts/Satoshi_Complete/Satoshi-Regular.otf");
}

h1,
h2 {
  font-family: KS-extrabold !important;
}
h3,
h4 {
  font-family: KS-bold !important;
}
h5 {
  font-family: S-bold !important;
}
h6 {
  font-family: S-medium !important;
}
p,
a {
  font-family: S-regular !important;
}

.btn-blue {
  background-color: #007d53 !important;
  font-family: S-bold !important;
  color: #fff !important;
  font-size: 15px !important;
}
.btn-blue-outline {
  background-color: #fff !important;
  font-family: S-bold !important;
  color: #007d53 !important;
  border: 1px solid #007d53 !important;
  font-size: 15px !important;
}

.App {
  background: linear-gradient(
    45deg,
    rgba(217, 250, 239, 0.7),
    rgba(255, 255, 255, 0.7),
    rgba(231, 242, 255, 0.7)
  );
  /* background: linear-gradient(45deg, rgba(217, 250, 239, .7), rgba(255, 255, 255, .7), rgba(231, 242, 255, .7)),url("assets/bg.png") center center no-repeat; */
  background-size: cover;
  position: relative !important;
  min-height: 100vh;
}

.form-div fieldset {
  border: 1px solid #b1bbb8 !important;
  /* margin: 20px auto; */
}
legend {
  float: none !important;
  margin: 0 !important;
  padding: 0px 5px !important;
  width: unset !important;
  font-family: S-medium;
  font-size: 14px !important;
  color: #007d53;
}
.form-div input,
select {
  border: none !important;
  font: 14px S-medium;
  padding-bottom: 10px;
  background-color: transparent;
}
.form-div select,
.form-div select option {
  font: 13px S-regular !important;
}
.form-div input::placeholder {
  color: #a3a2a2;
}

.form-div input:focus,
.form-div select:focus {
  outline: none !important;
}

.process-tag {
  padding-top: 20px;
  position: relative;
}

.process-tag p {
  height: 25px;
  width: 25px;
  margin: 0 10px 0px 0px;
  /* background-color: #bbbbbb; */
  border: none;
  border-radius: 50%;
  /* display: inline-block; */
  border: 1px solid black;
  display: flex;
  opacity: 0.5;
  font-size: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.step-3 {
  width: 29px !important;
}
.step-4 {
  width: 31px !important;
}
.step-6 {
  width: 29px !important;
}

.progress-tag .progress-desc {
  position: absolute;
  height: 50px;
  border: 1px solid #000 !important;
  width: 1px;
  content: "";
  top: 0;
  left: 0;
  z-index: 1000000;
}

/* input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='date'],
input[type='month'],
input[type='tel'],
input[type='url'],
textarea,
select{
  height: 50px;
  padding:10px 20px;
  font-family: S-regular;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='tel']:focus,
input[type='url']:focus,
textarea:focus,
select:focus{
  border-color:#007d53;
  box-shadow:none ;
  outline:none ;
} */

label {
  font-family: S-regular;
}

.main-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  padding: 50px 10px;
  overflow-y: scroll;
}

.main-modal .close-modal {
  background-color: #fff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 2px solid #000;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35px;
  right: 20px;
  cursor: pointer;
}
.main-modal-content {
  border-radius: 10px !important;
  padding: 20px;
  position: relative;
}
.footer a {
  text-decoration: none;
  color: black;
}
.footer ul{
  display: flex; 
  list-style: none; 
  text-align: center;
  align-items: center;
  justify-content: space-between; 
  gap: 20; 
  padding: 0px;
  margin: 0px
}

@media screen and (max-width: 768px) {
  .footer ul {
    display: block;
  }
  .partner-step{
    padding: 2em 0px;
  }
  .step-3 {
    width: 26px !important;
  }
  .step-4 {
    width: 25px !important;
  }
  .step-6 {
    width: 26px !important;
  }
  hr{
    display: none;
  }
}
